import React from 'react'
import { compose } from 'recompose'
import { withAuthorization } from '../components/session'
import {
  Wrap,
  FormCopy,
  FormBox,
  FormFields,
  FormField,
  Title,
} from '../components/page/components'
import SEO from '../components/SEO'
import { Link } from 'gatsby'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'

const Account = ({ authUser, location }) => {
  const backUrl =
    location.state && location.state.prevPath ? location.state.prevPath : '/'

  const { fullName } = authUser

  return (
    <Wrap>
      <SEO title="Account" />
      <FocusHeader title="Account" backUrl={backUrl} />
      <FocusBody>
        <Title>{fullName}</Title>
        <FormBox>
          <FormFields isProcessing={false}>
            <FormField width={`100%`}>
              <FormCopy>
                {/* <p>{authUser.email}</p> */}
                <p>
                  <Link to="/account-change-email">Update your email</Link>
                  <br />
                  <Link to="account-change-pass">Update your password</Link>
                  <br />
                  <Link to="account-billing-change">
                    Update billing details
                  </Link>
                  <br />
                  <Link to="account-billing-history">View billing history</Link>
                  <br />
                  <Link to="account-plan-change">Change your plan</Link>
                  <br />
                  <Link to="account-cancel">Cancel account</Link>
                </p>
              </FormCopy>
            </FormField>
          </FormFields>
        </FormBox>
      </FocusBody>
    </Wrap>
  )
}

const condition = authUser => !!authUser
const AccountWithAuth = compose(withAuthorization(condition))(Account)
export default AccountWithAuth
